import m from 'mithril';
import { V2sendState2URL } from './common.js';
import { customDate, customTime, addMinutes, toPlainTS, toEscTS } from '../util.js';

var beginTSLabelMC = {
  view: function(vnode) {
    return(
      m("label", {
        for: "beginTS",
        class: "beginDTLabel"
      }, `Begin Date/Time: ${vnode.attrs.beginTime}`)
    );
  }
};

var beginDateMC = {
  view: function(vnode) {
    var result = m("input", {
      class: "beginYYYYMMDD",
      type: "date",
      id: "beginDate",
      name: "beginDate",
      value: vnode.attrs.beginDate,
      onchange: function(e) {
        var latest = {
          beginDate: e.target.value,
          beginTime: vnode.attrs.beginTime,
          endDate: vnode.attrs.endDate,
          endTime: vnode.attrs.endTime,
          habit: vnode.attrs.habit
        };
        V2sendState2URL(latest);
      }
    });
    return(result);
  }
}
var beginTimeMC = {
  view: function(vnode) {
    var result = m("input", {
      class: "beginHHMMSS",  
      type: "time",
      id: "beginTime",
      name: "beginTime",
      value: vnode.attrs.beginTime.replaceAll("^", ":"),
      onchange: function(e) {
        var latest = {
          beginDate: vnode.attrs.beginDate,
          beginTime: e.target.value.replaceAll(":", "^"),
          endDate: vnode.attrs.endDate,
          endTime: vnode.attrs.endTime,
          habit: vnode.attrs.habit
        };
        V2sendState2URL(latest);
      }
    });
    return(result);
  }
};

var beginTSButtonMC = {
  view: function(vnode) {
    var result = m("button", {
      class: "beginTSButton",
      onclick: function(e) {
        var latest = {
          beginDate: customDate(),
          beginTime: customTime(),
          endDate: vnode.attrs.endDate,
          endTime: vnode.attrs.endTime,
          habit: vnode.attrs.habit
        };
        V2sendState2URL(latest);
      }
    }, "TODAY");
    return(result);
  }
};

var beginTSMinus5ButtonMC = {
  view: function(vnode) {
    var result = m("button", {
      class: "beginTSMinusSmall",
      onclick: function(e) {
        const beginJSDT = toPlainTS({
            YYYYMMDD: vnode.attrs.beginDate,
            HHMMSS: vnode.attrs.beginTime
          });
        const endJSDT = toPlainTS({
            YYYYMMDD: vnode.attrs.endDate,
            HHMMSS: vnode.attrs.endTime
          });
        const deltaMM = -5;
        
        if (/* TODO: endTS is stale */ beginJSDT > endJSDT) {
          // set endTS to startTS + topup
          var newEndTS = toEscTS(addMinutes(endJSDT, deltaMM));
        } else {
          // topup current endTS
          var newEndTS = toEscTS(addMinutes(beginJSDT, deltaMM));
        }

        var latest = {
          endDate: vnode.attrs.endDate,
          endTime: vnode.attrs.endTime,
          beginDate: newEndTS.YYYYMMDD,
          beginTime: newEndTS.HHMMSS,
          habit: vnode.attrs.habit
        };
        V2sendState2URL(latest);
      }
    }, "-5min");
    return(result);
  }
};

var beginTSSection = [
  beginTSLabelMC,
  beginTimeMC,
  beginDateMC,
  beginTSButtonMC,
  beginTSMinus5ButtonMC,
];

export { beginTSSection };