import m from 'mithril';
// import { state } from './view.js';
import { delay, __DBL__, smlr } from './util.js';

function renewToken() {
  // __DBL__('callrAT ENTRY');
  return (new Promise((resolve, reject) => {
    // __DBL__('callrAT: Promise entry');
    try {
      // Settle this promise in the response callback for requestAccessToken()
      // __DBL__('callrAT: try entry');
      tokenClient.callback = (resp) => {
        if (resp.error !== undefined) {
          __DBL__(`tokenClient.callback: ${resp}`);
          reject(resp);
        }
        // GIS has automatically updated gapi.client with the newly issued access token.
        // __DBL__(`callrAT tokenClient.callback: gapi.client access token: ${smlr(JSON.stringify(gapi.client.getToken()))}`);
        resolve(resp);
      };
      let cred = gapi.client.getToken();
      // if prior cred exists, null it out like the button
      // before calling requestAccessToken() just for IOS Safari
      if (cred !== null) {
        gapi.client.setToken(null);
        // __DBL__('callrAT: setToken to BLANK past');
      }
      // __DBL__(`callrAT pre-tC.rAT: gapi.client access token: ${smlr(JSON.stringify(gapi.client.getToken()))}`);
      tokenClient.requestAccessToken();
      // __DBL__('callrAT: try exit');
    } catch (err) {
      __DBL__('callrAT: catch entry');
      __DBL__(err);
      reject(`getToken post-promise: ${err}`);
    }
  }));
}

function reqwNULL(state) {
  let begin_ts = new Date(state.entryDate.replace(/-/g, '/') + " " + state.beginTime.replace(/\^/g, ':')).toISOString();
  let end_ts = new Date(state.entryDate.replace(/-/g, '/') + " " + state.endTime.replace(/\^/g, ':')).toISOString();
  if (gapi.client.getToken() == null) {
    return (Promise.reject(new Error(`reqwNULL: null token found`)));
  } else {
    return (m.request({
      method: "POST",
      url: "https://www.googleapis.com/calendar/v3/calendars/primary/events",
      headers: {
        Authorization: `Bearer ${gapi.client.getToken().access_token}`
      },
      body: {
        "summary": state.habit,
        "start": {
          "dateTime": begin_ts,
          "timeZone": "Australia/Brisbane"
        },
        "end": {
          "dateTime": end_ts,
          "timeZone": "Australia/Brisbane"
        }
      }
    })
      .then(calendarAPIResponse => {
        document.getElementById('habitLog').innerHTML =
          `${(new Date()).toLocaleDateString()
} ${(new Date()).toLocaleTimeString()
} Logged: ${state.habit}, id=${smlr(JSON.stringify(calendarAPIResponse.id))}<br/>` + document.getElementById('habitLog').innerHTML;
      }));
  }
}

function reqWRetry(state) {
  __DBL__('<br/><br/>_____STARTING HABIT NEW ENTRY_____');
  __DBL__(`reqWRetry ENTRY: ${smlr(JSON.stringify(gapi.client.getToken()))}`);
  // TODO: make reqWRetry a Promise-returning function
  // TODO: make reqWRetry return a Promise but without creating a fresh Promise object. Instead, directly return the Promise from the reqwNULL Promise-chain
  reqwNULL(state)
    .catch(err => {
      __DBL__(JSON.stringify(`reqWRetry failure#1: ${JSON.stringify(err)}`));
      renewToken()
        .then(x => reqwNULL(state))
        .catch(
          err => {
            __DBL__(JSON.stringify(`reqWRetry failure#2: ${JSON.stringify(err)}`));
          }
        );
    });
}

function V2reqwNULL(state) {
  console.log(`V2reqwNULL: ${state.beginDate.replace(/-/g, '/') + " " + state.beginTime.replace(/\^/g, ':')}`);
  console.log(`V2reqwNULL: ${state.endDate.replace(/-/g, '/') + " " + state.endTime.replace(/\^/g, ':')}`);

  let begin_ts = new Date(state.beginDate.replace(/-/g, '/') + " " + state.beginTime.replace(/\^/g, ':')).toISOString();
  let end_ts = new Date(state.endDate.replace(/-/g, '/') + " " + state.endTime.replace(/\^/g, ':')).toISOString();
  console.log(`V2reqwNULL: ${begin_ts}, ${end_ts}`);

  if (gapi.client.getToken() == null) {
    return (Promise.reject(new Error(`V2reqwNULL: null token found`)));
  } else {
    return (m.request({
      method: "POST",
      url: "https://www.googleapis.com/calendar/v3/calendars/primary/events",
      headers: {
        Authorization: `Bearer ${gapi.client.getToken().access_token}`
      },
      body: {
        "summary": state.habit,
        "start": {
          "dateTime": begin_ts,
          "timeZone": "Australia/Brisbane"
        },
        "end": {
          "dateTime": end_ts,
          "timeZone": "Australia/Brisbane"
        }
      }
    })
      .then(calendarAPIResponse => {
        document.getElementById('habitLog').innerHTML =
          `${(new Date()).toLocaleDateString()
} ${(new Date()).toLocaleTimeString()
} Logged: ${state.habit}, id=${smlr(JSON.stringify(calendarAPIResponse.id))}<br/>` + document.getElementById('habitLog').innerHTML;
      }));
  }
}

function V2reqWRetry(state) {
  __DBL__('<br/><br/>_____STARTING HABIT NEW ENTRY_____');
  __DBL__(`V2reqWRetry ENTRY: ${smlr(JSON.stringify(gapi.client.getToken()))}`);
  // TODO: make reqWRetry a Promise-returning function
  // TODO: make reqWRetry return a Promise but without creating a fresh Promise object. Instead, directly return the Promise from the reqwNULL Promise-chain
  V2reqwNULL(state)
    .catch(err => {
      // __DBL__(JSON.stringify(`V2reqWRetry failure#1: ${JSON.stringify(err)}`));
      __DBL__(JSON.stringify(`V2reqWRetry failure#1: code = ${err.code}`));
      renewToken()
        .then(x => V2reqwNULL(state))
        .catch(
          err => {
            // __DBL__(JSON.stringify(`V2reqWRetry failure#2: ${JSON.stringify(err)}`));
            __DBL__(JSON.stringify(`V2reqWRetry failure#2: code = ${err.code}, msg = ${err.response.error.errors[0].message}`));
          }
        );
    });
}

export { reqWRetry, V2reqWRetry };