import m from 'mithril';
import { V2sendState2URL } from './common.js';
import { tsDiff } from '../util.js';
import { V2reqWRetry } from '../gapiHelpers.js';

var saveButtonMC = {
  view: function(vnode) {
    var result = m("button", {
        class: "saveHabitButton",
        onclick: function() {
          var latest = {
            beginDate: vnode.attrs.beginDate,
            beginTime: vnode.attrs.beginTime,
            endDate: vnode.attrs.endDate,
            endTime: vnode.attrs.endTime,
            habit: vnode.attrs.habit
          };
          latest.habit += tsDiff({
              begin_YYYYMMDD: vnode.attrs.beginDate,
              begin_t: vnode.attrs.beginTime,
              end_YYYYMMDD: vnode.attrs.endDate,
              end_t: vnode.attrs.endTime
            });

          V2reqWRetry(latest);
        }
      }, "Log Habit");
    return(result);
  }
};

var saveButtonSection = [
  saveButtonMC,
];

export { saveButtonSection };