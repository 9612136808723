import m from 'mithril';
import { V2sendState2URL } from './common.js';
import { customDate, customTime, addMinutes, toPlainTS, toEscTS } from '../util.js';

var endTSLabelMC = {
  view: function(vnode) {
    return(
      m("label", {
        for: "endTS",
        class: "endDTLabel"
      }, `End Date/Time: ${vnode.attrs.endTime}`)
    );
  }
};

var endDateMC = {
  view: function(vnode) {
    var result = m("input", {
      class: "endYYYYMMDD",
      type: "date",
      id: "endDate",
      name: "endDate",
      value: vnode.attrs.endDate,
      onchange: function(e) {
        var latest = {
          beginDate: vnode.attrs.beginDate,
          beginTime: vnode.attrs.beginTime,
          endDate: e.target.value,
          endTime: vnode.attrs.endTime,
          habit: vnode.attrs.habit
        };
        V2sendState2URL(latest);
      }
    });
    return(result);
  }
}
var endTimeMC = {
  view: function(vnode) {
    var result = m("input", {
      class: "endHHMMSS",  
      type: "time",
      id: "endTime",
      name: "endTime",
      value: vnode.attrs.endTime.replaceAll("^", ":"),
      onchange: function(e) {
        var latest = {
          beginDate: vnode.attrs.beginDate,
          beginTime: vnode.attrs.beginTime,
          endDate: vnode.attrs.endDate,
          endTime: e.target.value.replaceAll(":", "^"),
          habit: vnode.attrs.habit
        };
        V2sendState2URL(latest);
      }
    });
    return(result);
  }
};

var endTSButtonMC = {
  view: function(vnode) {
    var result = m("button", {
      class: "endTSPlus0",
      onclick: function(e) {
        var latest = {
          beginDate: vnode.attrs.beginDate,
          beginTime: vnode.attrs.beginTime,
          endDate: customDate(),
          endTime: customTime(),
          habit: vnode.attrs.habit
        };
        V2sendState2URL(latest);
      }
    }, "TODAY");
    return(result);
  }
};

var endTSPlus11ButtonMC = {
  view: function(vnode) {
    
    var result = m("button", {
      class: "endTSPlusSmall",
      onclick: function(e) {
        const beginJSDT = toPlainTS({
            YYYYMMDD: vnode.attrs.beginDate,
            HHMMSS: vnode.attrs.beginTime
          });
        const endJSDT = toPlainTS({
            YYYYMMDD: vnode.attrs.endDate,
            HHMMSS: vnode.attrs.endTime
          });
        const topUpMM = 11;
        
        if (/* TODO: endTS is stale */ beginJSDT > endJSDT) {
          // set endTS to startTS + topup
          var newEndTS = toEscTS(addMinutes(beginJSDT, topUpMM));
        } else {
          // topup current endTS
          var newEndTS = toEscTS(addMinutes(endJSDT, topUpMM));
        }

        var latest = {
          beginDate: vnode.attrs.beginDate,
          beginTime: vnode.attrs.beginTime,
          endDate: newEndTS.YYYYMMDD,
          endTime: newEndTS.HHMMSS,
          habit: vnode.attrs.habit
        };

        V2sendState2URL(latest);
      }
    }, "+11min");
    return(result);
  }
};

var endTSPlus61ButtonMC = {
  view: function(vnode) {

    var result = m("button", {
      class: "endTSPlusBig",
      onclick: function(e) {
        const beginJSDT = toPlainTS({
            YYYYMMDD: vnode.attrs.beginDate,
            HHMMSS: vnode.attrs.beginTime
          });
        const endJSDT = toPlainTS({
            YYYYMMDD: vnode.attrs.endDate,
            HHMMSS: vnode.attrs.endTime
          });
        const topUpMM = 61;

        if (/* TODO: endTS is stale */ beginJSDT > endJSDT) {
          // set endTS to startTS + topup
          var newEndTS = toEscTS(addMinutes(beginJSDT, topUpMM));
        } else {
          // topup current endTS
          var newEndTS = toEscTS(addMinutes(endJSDT, topUpMM));
        }

        var latest = {
          beginDate: vnode.attrs.beginDate,
          beginTime: vnode.attrs.beginTime,
          endDate: newEndTS.YYYYMMDD,
          endTime: newEndTS.HHMMSS,
          habit: vnode.attrs.habit
        };

        V2sendState2URL(latest);
      }
    }, "+61min");
    return(result);
  }
};

var endTSSection = [
  endTSLabelMC,
  endDateMC,
  endTimeMC,
  endTSButtonMC,
  endTSPlus11ButtonMC,
  endTSPlus61ButtonMC,
];
export { endTSSection };