import m from 'mithril';

function virtueEntry(virtueText) {
  return(
    m("option", { value: virtueText }, virtueText)
  );
}

const virtues = [
  m("option", { value: " " }, "Pick a virtue"),
  virtueEntry("iphone charge: "),
  virtueEntry("5DBRP: "),
  virtueEntry("600mL water"),
  virtueEntry("jog"),
  virtueEntry("5pu,11sq,5pu"),
  virtueEntry("start IMFAST"),
  virtueEntry("end IMFAST"),
  virtueEntry("1 x 4pu,11sq,4pu"),
  virtueEntry("SR: "),
  virtueEntry("BrSp"),
  virtueEntry("gratitude: "),
  virtueEntry("work train"),
  virtueEntry("train home"),
  virtueEntry("WHM"),
  virtueEntry("coldshower aka cs"),
];

export {virtues};