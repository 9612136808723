function customDate() {
  let now = new Date();
  // toISOString is in UTC, but we want to use local time
  let lpad20s = (x) => { return String(x).padStart(2, "0") };
  return (
    [lpad20s(now.getFullYear()),
    lpad20s(now.getMonth() + 1),
    lpad20s(now.getDate())].join('-')
  );
}
//TODO: refactor customDate() into customDate() and generalised escapeNowYYYYMMDD()
//      where customDate() is escapeNowYYYYMMDD(new Date())

function customTime() {
  // return a date separated with '^' due to Mithril routing weirdness
  let now = new Date();
  let curTime = now.toTimeString().slice(0, 8).replaceAll(":", "^");
  return (curTime);
}
//TODO: refactor customTime() into specific customTime() and generalised escapeNowHHMMSS()
//      where customTime() is escapeNowHHMMSS(new Date())

function toPlainTS(spec) {
  let {YYYYMMDD, HHMMSS} = spec;
  // input Escaped TS
  // output Date object
  return(new Date(
    `${spec.YYYYMMDD}T${spec.HHMMSS.replaceAll("^", ":")}`
  ));
}

function toEscTS(jsDt) {
  // input Date object
  // output Escaped TS
  let lpad20s = (x) => { return String(x).padStart(2, "0") };
  let YYYYMMDD =[lpad20s(jsDt.getFullYear()),
    lpad20s(jsDt.getMonth() + 1),
    lpad20s(jsDt.getDate())].join('-');

  let HHMMSS = jsDt.toTimeString().slice(0, 8).replaceAll(":", "^");
  return({
    YYYYMMDD: YYYYMMDD,
    HHMMSS: HHMMSS // eg "03^00^00"
  });
}

function addMinutes(jsDt, extraMM) {
  // input Date object
  // output Date object
  const result = new Date(jsDt.getTime() + (extraMM * 60000));
  return(result);
}

// Apologies for the "1970-01-01" hack.
// This is because I decided the state object was going
// to mirror the fields on the HTML.
// The alternative is to use ISO 8601 in the state
// and write even more crufty code to interface with
// the HTML time and date fields.
// 
// This was the least worst option because
// I isolated the cruft into just time function
//
// CLEANER TODO: use ISO 8601 EVERYWHERE INCLUDING THE HTML
function timeDiff({begin_t = "", end_t = ""} = {}) {
  let begin_ts = new Date("1970/01/01" + " " + begin_t.replaceAll("^", ":"));
  let end_ts = new Date("1970/01/01" + " " + end_t.replaceAll("^", ":"));
  
  let tot_ss = Math.floor((end_ts - begin_ts) / 1000);
  let ss = tot_ss % 60;
  let mm = Math.floor(tot_ss / 60) % 60;
  let hh = Math.floor(tot_ss / 3600) % 3600;
  
  return(`: ${hh}h${mm}m${ss}s`);
} 

function tsDiff({begin_YYYYMMDD = "", begin_t = "", end_YYYYMMDD = "", end_t = ""} = {}) {
  let begin_ts = new Date(begin_YYYYMMDD.replace(/-/g, '/') + " " + begin_t.replaceAll("^", ":"));
  let end_ts = new Date(end_YYYYMMDD.replace(/-/g, '/') + " " + end_t.replaceAll("^", ":"));

  let tot_ss = Math.floor((end_ts - begin_ts) / 1000);
  console.log(`tsDiff: ${begin_ts}, ${end_YYYYMMDD}, ${end_ts}, ${tot_ss}`)
  let ss = tot_ss % 60;
  let mm = Math.floor(tot_ss / 60) % 60;
  let hh = Math.floor(tot_ss / 3600) % 3600;

  return(`: ${hh}h${mm}m${ss}s`);
} 

function delay(ms) {
  return new Promise(resolve => {
    __DBL__(`BEGIN delay ${ms} ms`);
    setTimeout(() => {
      __DBL__(`END   delay ${ms} ms`);
      resolve();
    }, ms);
  });
}

function __DBL__(txt) {
  let dest = document.getElementById('gapiLog');
  dest.innerHTML += `${(new Date()).toISOString()}: ${txt}<br/>`;
}

function smlr(iStr) {
  return (iStr.slice(0, 4) +
          "..." + 
          iStr.slice(-4));
}

export {customDate, customTime, addMinutes, toPlainTS, toEscTS, timeDiff, tsDiff, delay, __DBL__, smlr};