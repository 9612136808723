import m from 'mithril';
import { virtues } from '../virtues.js';
import { V2sendState2URL } from './common.js';

var habitLabelMC = {
  view: function(vnode) {
    return(m("label", {
      for: "habit",
      class: "habitLabel"
    }, "Habit"));
  } 
};

var habitListMC = {
  view: function(vnode) {
    var result = m("select", {
      id: "habit",
      class: "habitList",
      name: "habit",
      value: vnode.attrs.habit,
      onchange: function(e) {
        var latest = {
          beginDate: vnode.attrs.beginDate,
          beginTime: vnode.attrs.beginTime,
          endDate: vnode.attrs.endDate,
          endTime: vnode.attrs.endTime,
          habit: e.target.value
        };
        V2sendState2URL(latest);
      }
    }, virtues);
    return(result);
  }
};

var habitTextMC = {
  view: function(vnode) {
    var result = m("input", {
        type: "text",
        class: "habitText",
        id: "habitFreeText",
        name: "habitFreeText",
        value: vnode.attrs.habit,
        onchange: function(e) {
          var latest = {
            beginDate: vnode.attrs.beginDate,
            beginTime: vnode.attrs.beginTime,
            endDate: vnode.attrs.endDate,
            endTime: vnode.attrs.endTime,
            habit: e.target.value
          };
          V2sendState2URL(latest);
        }
      });
    return(result);
  }
};

var habitSection = [
  habitLabelMC,
  habitListMC,
  habitTextMC
];

export { habitSection };